import React, { useEffect } from 'react';
import { Box, Theme } from '@mui/material';
import { useRouter } from 'next/router';
import { useStyles } from '../styles';
import s from '@components/common/Layout/Layout.module.css';
import classNames from 'classnames';
import { NexusFooter } from 'Components/NexusNavigation/NexusFooter';
import { withDemiplaneUrqlClient } from 'Hocs/WithDemiplaneUrqlClient';
import { NexusTopNavigation } from 'Components/TopNavigation/Nexus/NexusTopNavigation';
import { RecoilRootProvider } from 'src/state/providers/RecoilRootProvider';
import { SnackbarProvider } from 'notistack';
import { DefaultSeo } from 'next-seo';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { RxJsEmitterProvider } from 'src/state/providers/RxJsEmitterProvider';
import { dynamicImport } from 'src/lib/dynamicImport';
import { useCheckLogin } from 'src/utils/checkLogin';

const RouteListener = dynamicImport(
  'RouteListener',
  () => import('Components/RouteListener').then((mod) => mod.RouteListener),
  { ssr: false }
);

const NexusListener = dynamicImport(
  'NexusListener',
  () => import('Components/NexusListener').then((mod) => mod.NexusListener),
  { ssr: false }
);

const DrawerController = dynamicImport(
  'DrawerController',
  () =>
    import('Components/DrawerController').then((mod) => mod.DrawerController),
  { ssr: false }
);

const WindowResize = dynamicImport(
  'WindowResize',
  () => import('Components/WindowResize').then((mod) => mod.WindowResize),
  { ssr: false }
);

const AuthenticatedSetup = dynamicImport(
  'AuthenticatedSetup',
  () =>
    import('Components/AuthenticatedSetup').then(
      (mod) => mod.AuthenticatedSetup
    ),
  { ssr: false }
);

interface INexusLayoutProps {
  theme?: Theme;
  children?: any;
  pageProps: any;
}

const NexusLayoutBase = ({ children, pageProps }: INexusLayoutProps) => {
  const classes = useStyles();
  const router = useRouter();
  const { nexusSlug } = router.query;
  const { checkLogin } = useCheckLogin(
    router,
    pageProps?.topNav?.currentUser?.id
  );
  const [sentLoginEvent, setSentLoginEvent] = React.useState(false);

  useEffect(() => {
    const clearRegistrationLocalStorage = async () => {
      if (!!pageProps.topNav.currentUser) {
        window.localStorage.removeItem('account-setup-form-1');
        window.localStorage.removeItem('account-setup-form-2');
        window.localStorage.removeItem('account-setup-form-3');
      }
    };

    clearRegistrationLocalStorage();
  }, [pageProps.topNav.currentUser]);

  useEffect(() => {
    let isMounted = true;

    if (!sentLoginEvent) {
      checkLogin();
      setSentLoginEvent(true);
    }

    return () => {
      if (!!isMounted) {
        isMounted = false;
      }
    };
  }, [checkLogin, sentLoginEvent]);

  const tempClasses = [classes.appContainer, s.root];

  const cn = classNames(tempClasses);

  return (
    <>
      <DefaultSeo
        title='Demiplane'
        description=''
        openGraph={{
          url: '/',
          title: 'Demiplane',
          description:
            'Find Your Next Adventure On Demiplane - Matchmaking, Video, Voice, Shared Journaling, And Much More To Come!',
          type: 'website',
          images: [
            {
              url: 'https://demiplane-static.s3-us-west-2.amazonaws.com/DemiplaneOG.jpg',
              alt: 'Demiplane',
            },
          ],
          site_name: 'Demiplane',
        }}
        twitter={{
          handle: '@DemiplaneRPG',
          cardType: 'summary_large_image',
        }}
      />
      <WindowResize />
      <RouteListener />
      <DrawerController />
      <NexusListener />
      <AuthenticatedSetup />
      <Box className={cn}>
        <NexusTopNavigation topNav={pageProps.topNav} />

        <Box
          sx={{
            height: {
              xs: 'calc(100dvh - 72px)',
              [`@media (minWidth:1365px)`]: 'calc(100dvh - 80px)',
            },
          }}
          position={'relative'}
          overflow={{ y: 'auto' }}
          bgcolor={'black'}
          id='layout-root'
        >
          {children}
          {nexusSlug === 'test' && <NexusFooter />}
        </Box>
      </Box>
    </>
  );
};

export const NexusLayout = withDemiplaneUrqlClient(
  (props: INexusLayoutProps) => {
    return (
      <UserProvider>
        <RecoilRootProvider>
          <RxJsEmitterProvider>
            <SnackbarProvider maxSnack={3}>
              <NexusLayoutBase {...props} />
            </SnackbarProvider>
          </RxJsEmitterProvider>
        </RecoilRootProvider>
      </UserProvider>
    );
  }
);
